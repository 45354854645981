import './App.css';
import Header from './components/header/header';
import Home from './components/home/home';
import About from './components/about/About';
import Skills from './components/skills/skills';
import Services from './components/Services/Services';
import Qualification from './components/qualification/Qualification';
import Contact from './components/Contact/Contact';
import Work from './components/work/Work';
import Footer from './components/footer/Footer';
function App() {
  return (
<>
<Header />
<Home />
<About /> 
<Skills />
<Services />
<Work/>
<Qualification />
<Contact />
<Footer />
</>
  );
}

export default App;
