import React from 'react'
import "./footer.css"
const Footer = () => {
  return (
    <footer className="footer-container">
    <div className="footer-content">
      <div className="copyright">
        &copy; {new Date().getFullYear()} All rights reserved.
      </div>
      <div className="social-icons">
        {/* Add your social media icons here */}
        <i className="fab fa-facebook"></i>
        <i className="fab fa-twitter"></i>
        <i className="fab fa-instagram"></i>
        <i className="fab fa-linkedin"></i>
      </div>
    </div>
  </footer>
  )
}

export default Footer