import React from 'react'
import "./skills.css"

const Frontend = () => {
  return (
    <div className="skills__content">
        <h3 className="skills__title">Front end developer</h3>

        <div className="skills__box">
            <div className="skills__group">
                <div className="skills__data">
                    <i class=""bx bx-badge-check></i>

                    <div>
                        <h3 className="skills__name">Html</h3>
                        <span className="skills__level">basics</span>
                    </div>
                </div>

                <div className="skills__data">
                    <i class=""bx bx-badge-check></i>

                    <div>
                        <h3 className="skills__name">CSS</h3>
                        <span className="skills__level">Basics</span>
                    </div>
                </div>

                <div className="skills__data">
                    <i class=""bx bx-badge-check></i>

                    <div>
                        <h3 className="skills__name">javascript</h3>
                        <span className="skills__level">advanced</span>
                    </div>
                </div>

                <div className="skills__data">
                    <i class=""bx bx-badge-check></i>

                    <div>
                        <h3 className="skills__name">bootstrap</h3>
                        <span className="skills__level">advanced</span>
                    </div>
                </div>
                <div className="skills__data">
                    <i class=""bx bx-badge-check></i>

                    <div>
                        <h3 className="skills__name">tailwind css</h3>
                        <span className="skills__level">advanced</span>
                    </div>
                </div>

                {/* <div className="skills__data">
                    <i class=""bx bx-badge-check></i>

                    <div>
                        <h3 className="skills__name">Git</h3>
                        <span className="skills__level">advanced</span>
                    </div>
                </div> */}

                <div className="skills__data">
                    <i class=""bx bx-badge-check></i>

                    <div>
                        <h3 className="skills__name">React</h3>
                        <span className="skills__level">advanced</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Frontend