import React from 'react'

const Adition = () => {
  return (
    <div className="skills__content">
    <h3 className="skills__title">ADITIONAL SKILLS</h3>

    <div className="skills__box">
        <div className="skills__group">
            <div className="skills__data">
                <i class=""bx bx-badge-check></i>

                <div>
                    <h3 className="skills__name">Graphic design</h3>
                    <span className="skills__level">advanced</span>
                </div>
            </div>
            <div className="skills__data">
                <i class=""bx bx-badge-check></i>

                <div>
                    <h3 className="skills__name">Git</h3>
                    <span className="skills__level">advanced</span>
                </div>
            </div>

            <div className="skills__data">
                <i class=""bx bx-badge-check></i>

                <div>
                    <h3 className="skills__name">CyberSecurity</h3>
                    <span className="skills__level">basics</span>
                </div>
            </div>

            <div className="skills__data">
                <i class=""bx bx-badge-check></i>

                <div>
                    <h3 className="skills__name">MS-Office</h3>
                    <span className="skills__level">advanced</span>
                </div>
            </div>

          
        </div>
    </div>
</div>
  )
}

export default Adition