import React from 'react'
import "./skills.css"
const Backend = () => {
  return (
    <div className="skills__content">
        <h3 className="skills__title">Backend developer</h3>

        <div className="skills__box">
            <div className="skills__group">
                <div className="skills__data">
                    <i class=""bx bx-badge-check></i>

                    <div>
                        <h3 className="skills__name">php</h3>
                        <span className="skills__level">advanced</span>
                    </div>
                </div>

                <div className="skills__data">
                    <i class=""bx bx-badge-check></i>

                    <div>
                        <h3 className="skills__name">laravel</h3>
                        <span className="skills__level">advanced</span>
                    </div>
                </div>
                <div className="skills__data">
                    <i class=""bx bx-badge-check></i>

                    <div>
                        <h3 className="skills__name">MERN Stack</h3>
                        <span className="skills__level">advanced</span>
                    </div>
                </div>

                <div className="skills__data">
                    <i class=""bx bx-badge-check></i>

                    <div>
                        <h3 className="skills__name">Mysql</h3>
                        <span className="skills__level">advanced</span>
                    </div>
                </div>
                <div className="skills__data">
                    <i class=""bx bx-badge-check></i>

                    <div>
                        <h3 className="skills__name">firebase</h3>
                        <span className="skills__level">advanced</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Backend