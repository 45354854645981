import React from 'react'

const Cisco = () => {
  return (
    <div className="skills__content">
        <h3 className="skills__title">Networking</h3>

        <div className="skills__box">
            <div className="skills__group">
                <div className="skills__data">
                    <i class=""bx bx-badge-check></i>

                    <div>
                        <h3 className="skills__name">CCNA1</h3>
                        <span className="skills__level">advanced</span>
                    </div>
                </div>

                <div className="skills__data">
                    <i class=""bx bx-badge-check></i>

                    <div>
                        <h3 className="skills__name">CCNA2</h3>
                        <span className="skills__level">advanced</span>
                    </div>
                </div>

                <div className="skills__data">
                    <i class=""bx bx-badge-check></i>

                    <div>
                        <h3 className="skills__name">CCNA3</h3>
                        <span className="skills__level">advanced</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Cisco