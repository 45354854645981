import React from 'react'
import Marquee from 'react-fast-marquee'
import tailwind from "../../assets/tailwindlogo.png"; // replace with your image paths
import javascript from "../../assets/jslogo.png";
import flutter from "../../assets/flutterlogo.png";
import Git from "../../assets/gitlogo.png";
import bootstrap from "../../assets/bootsteraplogo2.png";
import reactLogo from "../../assets/reactjslogo.png";
import mernlogo from "../../assets/mernlogo.png";
import firebaselogo from "../../assets/firebaselogo.png";
import mysqllogo from "../../assets/mysqllogo.png";
import laravellogo from "../../assets/laravellogo.png";
import phplogo from "../../assets/phplogo.jpg";
import cybersecuritylogo from "../../assets/Cybersecurity.png";
import ccnalogo from "../../assets/Ciscoccna.png";

function Slide() {
  return (
    <div>
         <Marquee>
          <div className='py-12'>
            <img src={tailwind} alt="" srcset="" className='mr-4 h-8'/>
          </div>
          <div>
            <img src={javascript} alt="" srcset="" className='mr-4 h-8' />
          </div>
          <div>
            <img src={flutter} alt="" srcset="" className='mr-4 h-8'/>
          </div>
          <div>
            <img src={Git} alt="" srcset="" className='mr-4 h-8'/>
          </div>
          <div>
            <img src={bootstrap} alt="" srcset="" className='mr-4 h-8' />
          </div>
          <div>
            <img src={reactLogo} alt="" srcset="" className='mr-4 h-8' />
          </div>
          <div>
            <img src={mernlogo} alt="" srcset="" className='mr-4 h-8' />
          </div>
          <div>
            <img src={firebaselogo} alt="" srcset="" className='mr-4 h-8' />
          </div>
          <div>
            <img src={mysqllogo} alt="" srcset="" className='mr-4 h-8' />
          </div>
          <div>
            <img src={laravellogo} alt="" srcset="" className='mr-4 h-8' />
          </div>
          <div>
            <img src={phplogo} alt="" srcset="" className='mr-4 h-8' />
          </div>
          <div>
            <img src={cybersecuritylogo} alt="" srcset="" className='mr-4 h-8' />
          </div>
          <div>
            <img src={ccnalogo} alt="" srcset="" className='mr-4 h-8' />
          </div>
         </Marquee>
    </div>
  )
}

export default Slide