import React from 'react'

const Info = () => {
  return (
    <div className="about__info grid">
        <div className="about__box">
            <i class="bx bx-award about__icon"></i>
            <h3 className="about__title">experiance</h3>
            <span className="about__subtitle">full stack developer</span>
        </div>

        <div className="about__box">
            <i class="bx bx-briefcase-alt"></i>
            <h3 className="about__title">complated</h3>
            <span className="about__subtitle"> more than 7+ project</span>
        </div>

        <div className="about__box">
            <i class="bx bx-support"></i>
            <h3 className="about__title">support</h3>
            <span className="about__subtitle">online 24/7</span>
        </div>
    </div>
  )
}

export default Info