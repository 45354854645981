import React, { useState } from 'react'
import "./header.css"
const Header = () => {
    // toggle menu 
    const [Toggle, setShowMenu] = useState(false);
    const [activeNav, setActiveNav] = useState('#home');
  return (
    <header className='header'>
        <nav className='nav container'>
            <a href='index.html' className='nav__logo'>Fuad Abdurahman</a>

            <div className={Toggle?"fuad":"nav__menu"}>
                <ul className='nav__list grid'>
                    <li className='nav_item'>
                        <a href='#home'onClick={()=>setActiveNav("#home")}  className={activeNav === "#home" ? "nav__link active-link":"nav__link"} >
                            <i className='uil  uil-estate nav__icon'></i>Home
                        </a>
                    </li>

                    <li className='nav_item'>
                        <a href='#about' onClick={()=>setActiveNav("#about")}  className={activeNav === "#about" ? "nav__link active-link":"nav__link"}>
                            <i className='uil uil-user nav__icon'></i>About
                        </a>
                    </li>

                    <li className='nav_item'>
                        <a href='#skills' onClick={()=>setActiveNav("#skills")}  className={activeNav === "#skills" ? "nav__link active-link":"nav__link"}>
                            <i className='uil uil-file-alt nav__icon'></i>skills 
                        </a>
                    </li>

                    <li className='nav_item'>
                        <a href='#services' onClick={()=>setActiveNav("#services")}  className={activeNav === "#services" ? "nav__link active-link":"nav__link"}>
                            <i className='uil uil-briefcase-alt nav__icon'></i>service
                        </a>
                    </li>


                    <li className='nav_item'>
                        <a href='#work' onClick={()=>setActiveNav("#work")}  className={activeNav === "#work" ? "nav__link active-link":"nav__link"}>
                            <i className='uil uil-scenery nav__icon'></i>projects
                        </a>
                        </li>


                    <li className='nav_item'>
                        <a href='#contact'  onClick={()=>setActiveNav("#contact")}  className={activeNav === "#contact" ? "nav__link active-link":"nav__link"}>
                            <i className='uil uil-message nav__icon'></i>contact
                        </a>
                    </li>
                </ul>

                <i class="uil uil-times nav__close" onClick={()=>setShowMenu(!Toggle)}></i>
            </div>

            <div className='nav__toggle' onClick={() => setShowMenu(!Toggle)}>
                <i class="uil uil-apps"></i>
            </div>
        </nav>
    </header>
  );
}

export default Header