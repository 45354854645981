import React, { useEffect, useState } from 'react'
import { projectsData} from './Data'
import { projectsNav } from './Data'
import WorkItems from './WorkItems'

function Works() {
    const [item, setItem] = React.useState({name:"all"});
    const [projects,setprojects]=useState([]);
    const [active, setActive] = useState(0);

    useEffect(() => {
        if(item.name === "all"){
            setprojects(projectsData);
        }else{
            const newProjects = projectsData.filter((project) => {
                return project.category === item.name
            }); 
            setprojects(newProjects);
        }
    },[item]);
    const handleClick = (e,index) => {
        setItem({name: e.target.textContent.toLowerCase()});
        setActive(index);
    };
  return (
    <div>
        <div className='work__filter'>
        {projectsNav.map((nav, index) => {
           return <span onClick={(e)=>{
            handleClick(e,index);
           }}
            className={`${active === index ? "active" : ""}
            work__item`}
             key={index}>
                {nav.name}
                </span>
})}
    </div>
    <div className="work__container container grid">
        {projects.map((item) => {
            return <WorkItems item={item} key={item.id}/>
              
        })}
    </div>
    </div>
  )
}

export default Works