import React, { useState } from 'react'
import "./Services.css"
const Services = () => {
    const [toggleState, setToggleState] = useState(0);

    const toggleTab = (index) => {
        console.log('Clicked tab', index);
        setToggleState(index);
    }

  return (
    <section className="services section" id="services">
        <h2 className="section__title">Services</h2>
        <span className="section__subtitle">What I Offer</span>
        <div className="services__container container grid">
            <div className="services__content">
                <div>
                    <i className="uil uil-laptop services__icon"></i>
                    <h3 className='services__title'>Product<br/>developer</h3>
                </div>
                <span className="services__button" onClick={()=>toggleTab(1)}>View more <i className="uil uil-arrow-right"></i></span>

                <div className={toggleState === 1 ? "fuad" : "services__modal"}>
                    <div className="services__modal-content">
                    <i onClick={toggleTab} className="uil uil-times services__modal-close"></i>
                    <h3 className="services__modal-title">product designer</h3>
                    <p className="services__modal-description">
                        i provide quality work to clients and componies
                    </p>

                    <ul className="services__model-services grid">
                        <li className="services__modal-services">
                            <i className="uil uil-check-circle services__model-icon"></i>
                            <p className="services__model-info">i develop the user interface.</p>
                        </li>

                        <li className="services__modal-services">
                            <i className="uil uil-check-circle services__model-icon"></i>
                            <p className="services__model-info">web page development</p>
                        </li>

                        <li className="services__modal-services">
                            <i className="uil uil-check-circle services__model-icon"></i>
                            <p className="services__model-info">mobile app development</p>
                        </li>
                        <li className="services__modal-services">
                            <i className="uil uil-check-circle services__model-icon"></i>
                            <p className="services__model-info">i position your company system</p>
                        </li>
                    </ul>
                    </div>
                </div>
            </div>


            <div className="services__content">
                <div>
                    <i className="uil uil-desktop services__icon"></i>
                    <h3 className='services__title'>Networking<br/>Expert</h3>
                </div>
                <span  onClick={()=>toggleTab(2)} className="services__button">View more <i className="uil uil-arrow-right"></i>
                </span>

                <div className={toggleState === 2 ?"fuad":"services__modal"}>
                    <div className="services__modal-content">
                    <i onClick={()=>toggleTab(0)}  className="uil uil-times services__modal-close"></i>
                    <h3 className="services__modal-title">Networking Expert</h3>
                    <p className="services__modal-description">
                        i provide quality work to clients and componies
                    </p>

                    <ul className="services__model-services grid">
                        <li className="services__modal-services">
                            <i className="uil uil-check-circle services__model-icon"></i>
                            <p className="services__model-info">i config cisco route configuration.</p>
                        </li>

                        <li className="services__modal-services">
                            <i className="uil uil-check-circle services__model-icon"></i>
                            <p className="services__model-info">network cable mentenance</p>
                        </li>

                        <li className="services__modal-services">
                            <i className="uil uil-check-circle services__model-icon"></i>
                            <p className="services__model-info">advanced network installing </p>
                        </li>
                        <li className="services__modal-services">
                            <i className="uil uil-check-circle services__model-icon"></i>
                            <p className="services__model-info">i position your company services</p>
                        </li>
                    </ul>
                    </div>
                </div>
            </div>


            <div className="services__content">
                <div>
                    <i className="uil uil-web-grid services__icon"></i>
                    <h3 className='services__title'>Product<br/>Designer</h3>
                </div>
                <span onClick={()=>toggleTab(3)} className="services__button">View more <i className="uil uil-arrow-right"></i></span>

                <div className={toggleState === 3 ?"fuad":"services__modal"}>
                    <div className="services__modal-content">
                    <i onClick={()=>toggleTab(0)} className="uil uil-times services__modal-close"></i>
                    <h3 className="services__modal-title">product designer</h3>
                    <p className="services__modal-description">
                        i provide quality work to clients and componies
                    </p>

                    <ul className="services__model-services grid">
                        <li className="services__modal-services">
                            <i className="uil uil-check-circle services__model-icon"></i>
                            <p className="services__model-info">i create graphic design.</p>
                        </li>

                        <li className="services__modal-services">
                            <i className="uil uil-check-circle services__model-icon"></i>
                            <p className="services__model-info">advanced use of ms-office</p>
                        </li>

                        <li className="services__modal-services">
                            <i className="uil uil-check-circle services__model-icon"></i>
                            <p className="services__model-info">i create beautiful content</p>
                        </li>
                        <li className="services__modal-services">
                            <i className="uil uil-check-circle services__model-icon"></i>
                            <p className="services__model-info">i position your company brand</p>
                        </li>
                    </ul>
                    </div>
                </div>
            </div>

        </div>
    </section>
  )
}

export default Services