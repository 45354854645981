import React from 'react'
import "./home.css"
const Social = () => {
  return (
    <div className="grid gap-4">
        <a href="https://t.me/fuad0859" className="home__social-icon" rel='noreferrer' target='_blank'>
        <i class="uil uil-telegram"></i>
        </a>

        <a href="https://www.linkedin.com/in/fuad-abdurahman-31b53325b/" className="home__social-icon" rel='noreferrer' target='_blank'>
            <i class="uil uil-linkedin-alt"></i>
        </a>

        <a href="https://github.com/Fuadekoo" className="home__social-icon" rel='noreferrer' target='_blank'>
        <i class="uil uil-github-alt"></i> 
        </a>
    </div>
  )
}

export default Social