import React from 'react'
import "./qualification.css"
const Qualification = () => {
  return (
    <div className="qualification section">
        <h2 className="section__title">Qualification</h2>
        <span className="section__subtitle">my personal journey</span>

        <div className="qualification__container container">
            <div className="qualification__tabs">
                <div className="qualification__button qualification__active
                button--flex">
                    <i className="uil uil-graduation-cap qualification__icon"></i>Education
                </div>

                <div className="qualification__button button--flex">
                    <i className="uil uil-briefcase-alt qualification__icon"></i>Experiance
                </div>
            </div>

            <div className="qualification__sections">
                <div className="qualification__content">

                    <div className="qualification__data">
                        <div>
                        <div>
                            <span className="qualification__rounder"></span>
                            <span className="qualification__line"></span>
                        </div>
                        </div>
                        <div>
                        <h3 className="qualification__title">software enginering</h3>
                        <span className="qualification__subtitle">wollo -university</span>
                        <div className="qualification__calender">
                            <i className="uil uil-calendar-alt"></i>2019-2024
                        </div>
                        </div>
                    </div>


                    <div className="qualification__data">
                        <div>
                        <div>
                            <span className="qualification__rounder"></span>
                            <span className="qualification__line"></span>
                        </div>
                        </div>
                        <div>
                        <h3 className="qualification__title">web development expert</h3>
                        <span className="qualification__subtitle">remotely</span>
                        <div className="qualification__calender">
                            <i className="uil uil-calendar-alt"></i>2022-present
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Qualification