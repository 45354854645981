import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import './contact.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Contact = () => {
  const form = useRef();
  const [errors, setErrors] = useState({});
  const [successMessage, setSuccessMessage] = useState('');

  

  const validateForm = () => {
    let isValid = true;
    const newErrors = {};

    if (!form.current.name.value.trim()) {
      newErrors.name = 'Name is required';
      isValid = false;
    }

    if (!form.current.email.value.trim()) {
      newErrors.email = 'Email is required';
      isValid = false;
    }

    if (!form.current.project.value.trim()) {
      newErrors.project = 'Project description is required';
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const sendEmail = (e) => {
    e.preventDefault();
    if (!validateForm()) {
      return; // If the form is not valid, return early
    }

    if (!form.current.name.value.trim() || !form.current.email.value.trim() || !form.current.project.value.trim()) {
      toast.error("Please fill out all fields.");
    } else {
      emailjs
        .sendForm(
          'service_pjdiddd',
          'template_p49jjqi',
          form.current,
          'AgyYuaXtWjeWEoB_D'
        )
        .then(() => {
          setSuccessMessage('Message sent successfully!');
          toast.success("Message sent successfully!");
          e.target.reset();
        })
        .catch((error) => {
          console.error('Error sending email:', error);
          toast.error("Error sending email.");
        });
    }
  };

  return (
    <section className="contact section" id="contact">
      <h2 className="section__title">Get In Touch</h2>
      <span className="section__subtitle">Contact Me</span>

      <div className="contact__container container grid">
        <div className="contact__content">
          <h3 className="contact__title">Talk To Me</h3>

          <div className="contact__info">
            <div className="contact__card">
              <i className="bx bx-mail-send contact__cart-icon"></i>
              <h3 className="contact__card-title">Email</h3>
              <span className="contact__card-data">fuadabdurahman0859@gmail.com</span>
              <a href="mailto:fuadabdurahman0859@gmail.com.com" className="contact__button">
                write me
                <i className="bx bx-right-arrow-alt contact__button-icon"></i>
              </a>
            </div>

            <div className="contact__card">
              <i className="bx bx-phone contact__cart-icon"></i>
              <h3 className="contact__card-title">phone number</h3>
              <span className="contact__card-data">+251-910737199</span>
              <a href="/" className="contact__button">
                <i className="bx bx-right-arrow-alt contact__button-icon"></i>
              </a>
            </div>

            <div className="contact__card">
              <i className="bx bx-phone contact__cart-icon"></i>
              <h3 className="contact__card-title">Another phone number</h3>
              <span className="contact__card-data">+251-707737199</span>
              <a href="/" className="contact__button">
                <i className="bx bx-right-arrow-alt contact__button-icon"></i>
              </a>
            </div>
          </div>
        </div>
        <div className="contact__content">
          <h3 className="contact__title">write me your project</h3>

          <form className="contact__form" ref={form} onSubmit={sendEmail}>
            {successMessage && <div className="success-message">{successMessage}</div>}
            <div className="contact__form-div">
              <label htmlFor="name" className="contact__form-tag">
                name
              </label>
              <input
                type="text"
                name="name"
                id="name"
                className={`contact__form-input ${errors.name ? 'error' : ''}`}
                placeholder="Insert your Name"
              />
              {errors.name && <span className="text-red-500">{errors.name}</span>}
            </div>
            <div className="contact__form-div">
              <label htmlFor="email" className="contact__form-tag">
                Email
              </label>
              <input
                type="email"
                name="email"
                id="email"
                className={`contact__form-input ${errors.email ? 'error' : ''}`}
                placeholder="Insert your email"
              />
              {errors.email && <span className="text-red-500">{errors.email}</span>}
            </div>
            <div className="contact__form-div">
              <label htmlFor="project" className="contact__form-tag">
                Project
              </label>
              <textarea
                name="project"
                id="project"
                className={`contact__form-input ${errors.project ? 'error' : ''}`}
                placeholder="Write your project"
              ></textarea>
              {errors.project && <span className="text-red-500">{errors.project}</span>}
            </div>
            <button  className="button button--flex">Send Message</button>
            <ToastContainer />
          </form>
        </div>
      </div>
    </section>
  );
};

export default Contact;
