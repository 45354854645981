import React from 'react'
import "./skills.css"
import Frontend from './Frontend'
import Backend from './Backend'
import Cisco from './cisco'
import Adition from './adition'
import Slide from './Slide'

const Skills = () => {
  return (
    <>
    <section className='skills section' id="skills">
        <h2 className="section__title">skills</h2>
        <span className="section__subtitle">My technical level</span>

        <div className="skills__container container grid">
            <Frontend />

            <Backend />

            <Cisco />

            <Adition />
        </div>
       
    </section>
    <div>
        <Slide />
        </div>
    </>
  )
}

export default Skills