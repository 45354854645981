import React from 'react'
import "./Work.css"
import Works from './Works'
const Work = () => {
  return (
    <section className='work section' id="portfolio">
        <h2 className='section__title'>projects</h2>
        <span className="section__subtitle">Most Recent works</span>
        <Works/>
    </section>
  )
}

export default Work